import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import favicon from '../components/epiarc-icon.png'

const getTitle = (pageTitle, siteName) => {
  if (!pageTitle) {
    return siteName
  }
  return `${pageTitle} · ${siteName}`
}

const getAbsoluteUrl = (pageUrl, siteUrl) => {
  return (new URL(pageUrl, siteUrl)).toString()
}

const getImage = (pageImage, siteImage, siteUrl) => {
  if (pageImage) {
    return getAbsoluteUrl(pageImage, siteUrl)
  }
  return getAbsoluteUrl(siteImage, siteUrl)
}

function SEO({ description, lang, meta, keywords: pageKeywords, title, image: pageImage }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description
        const siteName = data.site.siteMetadata.name
        let keywords = data.site.siteMetadata.keywords

        if (pageKeywords) {
          keywords = [
            ...keywords,
            ...pageKeywords
          ]
        }

        const documentTitle = getTitle(title, siteName)
        const documentImage = getImage(pageImage, data.site.siteMetadata.image, data.site.siteMetadata.url)

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={documentTitle}
            link={[
              { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
            ]}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:site_name`,
                content: siteName,
              },
              {
                property: `og:title`,
                content: documentTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:image`,
                content: documentImage,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:title`,
                content: documentTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `twitter:image`,
                content: documentImage,
              },
              {
                name: `twitter:site`,
                content: `@${data.site.siteMetadata.twitterUsername}`,
              },
              {
                name: `twitter:creator`,
                content: `@${data.site.siteMetadata.twitterUsername}`,
              }
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          >
          <script type="application/ld+json">{
`
{
    "@context": "http://schema.org"
    "@type": "WebSite",
    "name": "${data.site.siteMetadata.name}",
    "description": "${metaDescription}",
    "url": "${data.site.siteMetadata.url}",
    "logo": "${getAbsoluteUrl(data.site.siteMetadata.image, data.site.siteMetadata.url)}"
}
`
          }</script>

          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        name
        title
        author
        url
        image
        description
        keywords
        twitterUsername
      }
    }
  }
`
