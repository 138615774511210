import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import logoSvg from './epiarc-launch-logo.svg'
import './Header.scss'

function Header() {

  return (
    <div className="Header">
      <div className="Header-grid">
        <div className="Header-logo-container">
          <Link className="Header-logo" to={`/`}>
            <img className="Header-logo-image" src={logoSvg} />
          </Link>
        </div>
        <div className="Header-nav-container">
          <div className="Header-nav">
            <ul>
              <li>
                <Link className="Header-nav-link" to="/concepts">Concepts</Link>
              </li>
              <li>
                <Link className="Header-nav-link" to="/paper">Paper</Link>
              </li>
              <li>
                <Link className="Header-nav-link" to="/rfc">RFC</Link>
              </li>
              <li>
                <Link className="Header-nav-link" to="/contact">Contact</Link>
              </li>
              <li>
                <a className="Header-nav-link" href="https://launch.blog">Blog</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
