import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import SVG from 'react-inlinesvg'
import epiarcLogoWhite from './epiarc-launch-logo-white.svg'
import {WebsiteIcon} from '@epi/epi-ui'

import './Footer.scss'

const getCurrentYear = () => {
  return (new Date()).getFullYear()
}

function Footer (props) {
  const {siteName} = props

  return (
    <div className="Footer">
      <footer>
        <div className="Footer-grid">
          <div className="Footer-logo-container">
            <div className="Footer-logo-container-inner">
              <Link className="Footer-logo" to={`/`}>
                <SVG src={epiarcLogoWhite} />
              </Link>
              <div className="Footer-copyright">
                © {getCurrentYear()} Epiarc.org
              </div>
            </div>
          </div>
          <div className="Footer-nav-container Footer-nav-company-container">
            <div className="Footer-nav Footer-nav-company">
              <div className="Footer-nav-heading Footer-nav-company-heading">
                Resources
              </div>
              <ul>
                <li>
                  <Link className="Footer-nav-link" to="/concepts">Concepts</Link>
                </li>
                <li>
                  <Link className="Footer-nav-link" to="/paper">Paper</Link>
                </li>
                <li>
                  <Link className="Footer-nav-link" to="/rfc">RFC</Link>
                </li>
                <li>
                  <Link className="Footer-nav-link" to="/contact">Contact</Link>
                </li>
                <li>
                  <a className="Footer-nav-link" href="https://launch.blog">Blog</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="Footer-nav-container Footer-nav-company-container">
            <div className="Footer-nav Footer-nav-company">
              <div className="Footer-nav-heading Footer-nav-company-heading">
                Contact
              </div>
              <ul>
                <li>
                  <a className="Footer-nav-link" href="mailto:info@epiarc.org"><WebsiteIcon name="envelope" fill />Email</a>
                </li>
                <li>
                  <a className="Footer-nav-link" href="https://facebook.com/epiarc.hq"><WebsiteIcon name="facebook" brand />Facebook</a>
                </li>
                <li>
                  <a className="Footer-nav-link" href="https://twitter.com/epiarc"><WebsiteIcon name="twitter" brand />Twitter</a>
                </li>
                <li>
                  <a className="Footer-nav-link" href="https://instagram.com/epiarc"><WebsiteIcon name="instagram" brand />Instagram</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="Footer-nav-container Footer-nav-legal-container">
            <div className="Footer-nav Footer-nav-legal">
              <div className="Footer-nav-heading Footer-nav-legal-heading">
                Legal
              </div>
              <ul>
                <li>
                  <Link className="Footer-nav-link" to="/legal/terms">Terms</Link>
                </li>
                <li>
                  <Link className="Footer-nav-link" to="/legal/privacy">Privacy</Link>
                </li>
                <li>
                  <Link className="Footer-nav-link" to="/legal/cookies">Cookies</Link>
                </li>
                <li>
                  <Link className="Footer-nav-link" to="/legal/suppliers">Suppliers</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
