import React from 'react'
import { Link } from 'gatsby'

import Header from './Header'
import Footer from './Footer'
import './Layout.scss'

class Layout extends React.Component {

  componentDidMount () {
    document.body.dataset.loaded = true
  }

  getCurrentYear () {
    return (new Date()).getFullYear()
  }

  render () {
    const { location, title, children, plain, root } = this.props
    const isHomepage = root
    let header

    if (isHomepage) {
      header = (
        <h1 className="Layout-title Layout-main-title Layout-site-title">
          <Link
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      header = (
        <h3 className="Layout-title Layout-site-title">
          <Link
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }
    return (
      <div className="Layout" data-plain={plain}>
        <div className="Layout-header">
          <Header />
        </div>
        <div className="Layout-content">
          {children}
        </div>
        <div className="Layout-footer">
          <Footer siteName={title} />
        </div>
      </div>
    )
  }
}

export default Layout
